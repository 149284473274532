import { Routes } from 'constants/routes'
import { trackEvent } from 'monitoring/events'

import { signOut, SignOutParams } from 'next-auth/react'
import router from 'next/router'

/**
 * Type guard to check if error is a Response object with status code
 * @param error - The error to check
 */
export const isResponseError = (error: unknown): error is Response =>
  error instanceof Response

// See [...nextauth].ts for more context. This results in a redirect to
// Cognito, which then redirects back to our app:
export const SIGN_OUT_REDIRECT = 'cognitoSignOut'

/**
 * Signs out the user by redirecting to the Cognito sign out endpoint.
 */
export const cognitoSignOut = async (): Promise<void> => {
  try {
    const signOutParams: SignOutParams = {
      callbackUrl: SIGN_OUT_REDIRECT,
      redirect: true,
    }

    await signOut(signOutParams)
  } catch (error) {
    if (isResponseError(error) && error.status === 404) {
      trackEvent('sign_out_error_404', {
        message: 'Sign out endpoint not found (404)',
        error,
      })
    }

    trackEvent('sign_out_error', {
      message: 'Failed to sign out',
      error,
    })
    // Redirect to sign in page regardless of error
    router.push({ pathname: Routes.SIGNIN, query: { error: 'signout_error' } })
  }
}
